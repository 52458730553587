@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
.HomePage_homePageWrapper__3Qw-Y {
  display: flex;
	flex-wrap: nowrap;
	min-height: 100vh;
}

.HomePage_pageWrapper__23TPb {
	display: flex;
	overflow: hidden;
}

.HomePage_contentWrapper___Z4c0 {
	max-height: 100vh;
  min-height: 100vh;
	width: 100%;
	overflow: auto;
}

.HomePage_componentBoxSearch__wwohq {
  margin: 0 25px 15px 25px;
  width: 50%;
}

.HomePage_recommendedMain__yLJga {
  margin-top: 70px;
}

.HomePage_fHome__3E6Ah {
  display: flex;
  flex-grow: 1;
  flex-basis: 50%;
}

.HomePage_p__3P-pA {
  margin-top: 1px;
  color: var(--primary-color);
  margin-bottom: 1px;
  font-weight: 500;
  font-size: 25px;
}



/* Home Card */
.HomePage_wrapper__nLnJY {
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.HomePage_search__2b14v {
  border: transparent;
  background-color: rgb(235, 237, 254);
  border-radius: 10px;
  flex-grow: 1;
  padding: 15px;
  margin-bottom: 20px;
}

.HomePage_p__3P-pA {
  margin-top: 1px;
  color: var(--primary-color);
  margin-bottom: 1px;
  font-weight: 500;
  font-size: 30px;
}

.HomePage_buttonsWrapper__1egN5 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin: 20px 0;
}

.HomePage_button__1VeQo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  color: rgb(190, 190, 190);
  cursor: pointer;
  margin: 5px 0;
  border: 1px solid transparent;
}

.HomePage_activeButton__2hTzG {
  background-color: rgb(62, 113, 143);
  color: white;
  border: 1px solid white;
  border-radius: 50%;
  box-shadow: 4px 4px 6px 2px rgba(0, 0, 0, 0.28);
}

.HomePage_buttonText__2DyyM {
  margin-top: 6px;
  color: grey;
}

.HomePage_activeButtonText__38EZZ {
  color: black;
}

.HomePage_fas__1DQ5q {
  font-size: 20px;
}

.HomePage_configuration__36EJN {
  margin-top: 10px;
  display: flex;
  grid-gap: 16px;
  gap: 16px;
}

.HomePage_input__1F4kG {
  background-color: rgb(242, 243, 254);
  border: 1px solid #e2e5fe;
  padding: 10px;
}

.HomePage_datePicker__2s96e {
  width: 50%;
}

.HomePage_groupSelect__3d-8V {
  width: 50%;
}

.HomePage_searchbtn__2BL3a {
  padding: 15px;
  color: white;
  background-color: var(--primary-color);
  border-radius: 5px;
  margin: 0 auto;
  font-size: 17px;
  width: 200px;
  margin-top: 15px;
  text-align: center;
  text-decoration: none;
}

.HomePage_recent__3qH-L {
  border: 1px solid;
}

.HomePage_recentSearches__29Ilw {
  font-weight: 700;
  color: black;
}

.HomePage_wrapper__nLnJY {
  padding-top: 35px;
  margin-top: 0px;
  /* margin-left: 40px;
  margin-right: 40px; */
  display: flex;
  flex-direction: column;
  /* flex-grow: 1; */
}


.HomePage_search__2b14v {
  border: transparent;
  background-color: rgb(235, 237, 254);
  border-radius: 10px;
  /* flex-grow: 1;	 */
  padding: 15px;
  margin-bottom: 20px;
}

.HomePage_h1__2kIFY {
  margin-top: 1px;
  color: var(--primary-color);
  margin-bottom: 1px;
  font-weight: 500;
  font-size: 25px;
}

.HomePage_p__3P-pA {
  margin-top: 1px;
  color: var(--primary-color);
  margin-bottom: 1px;
  font-weight: 500;
  font-size: 20px;
}

.HomePage_buttonsWrapper__1egN5 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin: 20px 0;
}

.HomePage_button__1VeQo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  color: rgb(190, 190, 190);
  cursor: pointer;
  margin: 5px 0;
  border: 1px solid transparent;
}

.HomePage_activeButton__2hTzG {
  background-color: rgb(62, 113, 143);
  color: white;
  border: 1px solid white;
  border-radius: 50%;
  box-shadow: 4px 4px 6px 2px rgba(0, 0, 0, 0.28);
}

.HomePage_buttonText__2DyyM {
  margin-top: 6px;
  color: grey;
}

.HomePage_activeButtonText__38EZZ {
  color: black;
}

.HomePage_fas__1DQ5q {
  font-size: 20px;
}

.HomePage_configuration__36EJN {
  margin-top: 10px;
  display: flex;
  /* flex-wrap: wrap; */
  grid-gap: 16px;
  gap: 16px;
}

.HomePage_input__1F4kG {
  background-color: rgb(242, 243, 254);
  border: 1px solid #e2e5fe;
  padding: 10px;
  text-align: center;
}

.HomePage_align_baseline__2ErC8 {
  align-items: baseline;
}

.HomePage_d_flex___KgvH {
  display: flex;
}

.HomePage_datePicker__2s96e {
  width: 50%;
}

.HomePage_groupSelect__3d-8V {
  width: 50%;
}

.HomePage_searchbtn__2BL3a {
  padding: 15px;
  color: white;
  background-color: var(--primary-color);
  border-radius: 5px;
  margin: 0 auto;
  font-size: 17px;
  width: 200px;
  margin-top: 15px;
  text-align: center;
  text-decoration: none;
}

.HomePage_disableBtn__3pNZD {
  cursor: not-allowed;
}


@media screen and (max-width: 768px) {
  .HomePage_datePicker__2s96e {
    width: calc(100% - 25px) !important;
  }

  .HomePage_componentBoxSearch__wwohq {
    width: 100%;
    margin: 0;
  }
}

/* .number {
  background-color: rgb(242, 243, 254);
  border: 1px solid #e2e5fe;
  padding: 10px 18px;
  text-align: center;
  font-size: 12px;
} */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance: textfield;
  /* Firefox */
}
.Sidebar_sidenav__1NWy5 {
  width: 98px;
  height: 100dvh !important;
  background-color: var(--primary-color);
  border-top-right-radius: 50px 40px;
  animation-duration: 0.6s;
  overflow-x: hidden;
  z-index: 11000;
  transition: width 0.3s ease;
  transform: translateX(0);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 9997;
}

.Sidebar_sideNavMobile__3yd5Q {
  position: fixed;
}

.Sidebar_hideAnimation__1LQJN {
  width: 0;
  transition: width 0.3s ease;
}

.Sidebar_showAnimation__201M2 {
  display: flex;
  transition: width 0.3s ease;
}

.Sidebar_img__2g8ev {
  margin-top: 50px;
  width: 100px;
}

.Sidebar_logo__28fX9 {
  margin-top: 50px;
  padding: 5px;
  width: 84px;
}

.Sidebar_buttonsWrapper__3jO6N {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.Sidebar_button__sJdix {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 58px;
  width: 58px;
  color: white;
  cursor: pointer;
  margin: 15px 0;
  border: 1px solid transparent;
  text-decoration: none;
  padding: 5px;
}

.Sidebar_activeButton__1DoIn {
  background-color: white;
  color: var(--primary-color);
  border: 1px solid white;
  border-radius: 50%;
  box-shadow: 4px 4px 6px 2px rgba(0, 0, 0, 0.28);
}

.Sidebar_buttonText__21SR5 {
  margin-top: 6px;
}

.Sidebar_showSideNavBtn__2oZsk {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 10px;
  color: var(--secondary-color);
  background-color: var(--primary-color);
  padding: 5px;
  border-radius: 0 50% 50% 0;
  height: 45px;
  width: 45px;
  box-shadow: 4px 4px 6px 2px rgb(0 0 0 / 28%);
  border: solid 1px white;
  border-left: 0;
  cursor: pointer;
  z-index: 1000;
}

.Sidebar_showSideNavBtnDetails__3-8K0 {
  bottom: 80px;
}

.Sidebar_hideSideNavBtn__19VqY {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 13px;
  color: var(--secondary-color);
  padding: 5px;
  height: 40px;
  width: 40px;
  box-shadow: -5px 0px 5px 0px rgb(0 0 0 / 28%);
  border-radius: 50%;
  cursor: pointer;
}

.Sidebar_hideSideNavBtnDetails__UfVoI {
  bottom: 85px;
}

.SearchComponent_wrapper__sHE0I {
  margin-top: 35px;
  display: flex;
  flex-direction: column;
}

.SearchComponent_search__SaYvh {
  border: transparent;
  background-color: rgb(235, 237, 254);
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
}

.SearchComponent_h1__1_YmO {
  margin-top: 1px;
  color: var(--primary-color);
  margin-bottom: 1px;
  font-weight: 500;
  font-size: 25px;
}
.SearchComponent_p__1KRl5 {
  margin-top: 1px;
  color: var(--primary-color);
  margin-bottom: 1px;
  font-weight: 500;
  font-size: 20px;
}

.SearchComponent_buttonsWrapper__2ubsW {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  grid-gap: 50px;
  gap: 50px;
  margin: 20px 0;
}

.SearchComponent_contentWrapper__2jCtJ {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SearchComponent_contentWrapper__2jCtJ:hover .SearchComponent_buttonText__3kChO {
  color: var(--text-hover-color);
}

.SearchComponent_button__1fBXX {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f3f4f6;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.SearchComponent_button__1fBXX i {
  font-size: 18px;
  transition: color 0.2s ease-in-out;
}

.SearchComponent_button__1fBXX:hover i {
  color: white;
}

.SearchComponent_button__1fBXX:hover {
  background-color: var(--icon-hover-color);
}

.SearchComponent_activeButton__2CXyg {
  background-color: var(--icon-hover-color);
  color: white;
}

.SearchComponent_activeButton__2CXyg i {
  color: white;
}

.SearchComponent_disableBtn__2uPnQ {
  opacity: 0.5;
  cursor: not-allowed;
}

.SearchComponent_buttonText__3kChO {
  margin-top: 8px;
  font-size: 14px;
  font-weight: 600;
  transition: color 0.3s ease;
}

.SearchComponent_activeButtonText__1mjpW {
  color: var(--text-hover-color);
  font-weight: 600;
}


.SearchComponent_fas__HSgMw {
  font-size: 20px;
}

.SearchComponent_searchPeopleWrapper__1Td4X {
  flex-wrap: wrap;
}

.SearchComponent_cityLists__3svLd {
  padding: 13px;
  background: none;
  border-color: rgb(165, 172, 242);
}

.SearchComponent_configuration__PjV1E {
  margin-top: 10px;
  display: flex;
  /* flex-wrap: wrap; */
  grid-gap: 16px;
  gap: 16px;
}

.SearchComponent_input__1NsC4 {
  background-color: rgb(242, 243, 254);
  border: 1px solid #e2e5fe;
  padding: 10px;
  text-align: center;
}

.SearchComponent_align_baseline__oR7Oc {
  align-items: baseline;
}

.SearchComponent_d_flex__1HDNa {
  display: flex;
}

.SearchComponent_datePicker__32v-T {
  width: 50%;
}

.SearchComponent_groupSelect__2NOlK {
  width: 50%;
}

.SearchComponent_searchbtn__22xcM {
  padding: 15px;
  color: white;
  background-color: var(--primary-color);
  border-radius: 5px;
  margin: 0 auto;
  font-size: 17px;
  width: 200px;
  margin-top: 15px;
  text-align: center;
  text-decoration: none;
}

.SearchComponent_disableBtn__2uPnQ {
  cursor: not-allowed;
}

.SearchComponent_inlineIcon__2E2EL {
  height: 15px;
  margin-left: 10px;
}

.SearchComponent_borderLeftNone__ikpRU {
  border-left: none;
}

.SearchComponent_borderRightNone__2_9m- {
  border-right: none;
}

@media screen and (max-width: 768px) {
  .SearchComponent_datePicker__32v-T {
    width: calc(100% - 25px) !important;
  }

  .SearchComponent_wrapper__sHE0I {
    margin: 35px 20px 0 20px;
  }
}

.SearchComponent_plusMinus__3jBuL {
  align-items: center;
  background-color: #fff;
  border-color: #727483;
  border-radius: 2500rem;
  border-style: solid;
  border-width: 1px;
  display: flex;
  height: 2rem;
  justify-content: center;
  width: 2rem;
  cursor: pointer;
}

.SearchComponent_logo__QF00A {
  color: #727483;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

.SearchComponent_formInput__2pkB9 {
  background-color: white;
  border: none;
  text-align: center;
  height: 15px;
  width: 15px;
  outline: none;
}

* {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

:root {
  --primary-color: #00446b;
  --success-color: rgb(42, 165, 58);
  --error-color: rgb(189, 7, 7);
  --secondary-color: white;
  --orange: rgb(253, 198, 84);
  --bp-medium: 768px;
  --bp-small: 576px;
  /* --animate-duration: 1000ms;  */
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.more-div {
  max-width: 1400px;
  margin: 10rem auto;
}
.more-div >h2 {
  text-align: center;
  color: var(--primary-color);
}
.card-wrap {
  display: flex;
  flex-wrap: wrap; 
  justify-content: space-evenly; 
  padding: 1px;
  grid-gap: 1px;
  gap: 1px;
}
.card-wrap {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 1800px; /* Limit container width on large screens */
  display: flex;
  flex-wrap: wrap; /* Wrap cards onto multiple lines */
  justify-content: space-between; /* Distribute cards evenly */
  margin: 0 auto; /* Center the grid horizontally */
  padding: 1rem;
  grid-gap: 1px;
  gap: 1px; /* Add spacing between cards */
}

.card {
  display: flex;
  flex-direction: column;
  flex: 0 0 45%; 
  min-width: 250px; 
  margin: 1rem 0.5rem; 
  border-radius: 0.5rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  font-family: sans-serif;
  overflow: hidden; 
}

/* Media queries for additional responsiveness */

@media (max-width: 768px) {
  .card {
    flex: 0 0 50%; /* Adjust card width for smaller screens (e.g., tablets) */
  }
}

@media (max-width: 480px) {
  .card {
    flex: 1 0 100%; /* Make cards full-width on very small screens (e.g., smartphones) */
    margin: 0.5rem; /* Adjust margins for smaller screens */
  }
}

  .card-image-wrapper {
	overflow: hidden;
  }
  
  .card-image {
	width: 100%;
	height: 150px; /* Adjust image height as needed */
	object-fit: cover; /* Maintain image aspect ratio */
	transition: transform 0.3s ease-in-out;
  }
  
  .card:hover .card-image {
	transform: scale(1.1); /* Zoom on hover */
  }
  
  .card-content {
	padding: 1rem;
	flex: 1 1; /* Allow content to fill remaining space */
  }
  
  .card-heading {
	font-size: 1.2rem;
	margin-bottom: 0.5rem;
	color: #333;
  }
  
  .card-subtitle {
	color: #888;
	margin-bottom: 1rem;
  }
  
  .card-list {
	list-style: none;
	padding: 0;
	margin: 0;
  }
  
  .card-list-item {
	margin-bottom: 0.5rem;
  }
  
  .card-link {
	text-decoration: none;
	color: #007bff;
	font-weight: bold;
	transition: all 0.3s ease-in-out;
  }
  
  .card-link:hover {
	color: #0056b3;
  }
  
.loaderWrapperRecents {
  height: 100%;
  min-height: -webkit-max-content;
  min-height: max-content;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  margin-bottom: 5%;
}

.loader {
  border: 8px solid rgba(63, 143, 201, 0.1);
  border-radius: 50%;
  border-top: 10px solid var(--primary-color);
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}
input,
textarea {
  background-color: rgb(242, 243, 254);
  border: 1px solid #e2e5fe;
  padding: 10px;
}

input {
  -webkit-appearance: none !important;
  appearance: none !important;
}


/* utils */
.border-top {
  border-top: 4px solid var(--primary-color);
}

.border-top-1 {
  border-top: 1px solid var(--primary-color);
}

.border-top-2 {
  border-top: 2px solid var(--primary-color);
}

.border-top-3 {
  border-top: 3px solid var(--primary-color);
}

.border-warning {
  border: 2px solid rgb(140, 10, 10) !important;
}

.text-primary {
  color: var(--primary-color);
  
}
.position{
  display: flex;
  align-items: center;
  justify-content: center;
}


.text-success {
  color: var(--success-color);
}
.text-green {
    color: var(--success-color);
    text-align: center;
    text-decoration: underline;
  }

  .text-orange {
    color: var(--orange);
  }

.text-danger{
  color: var(--error-color);
}
.text-black{
  color: black;
}

.text-light {
  color: rgba(109, 109, 109, 0.8);
}

.text-white {
  color: #fff;
}

.bg-orange {
  background-color: var(--orange);
}

.bg-success {
  background-color: var(--success-color);
}

.container {
  padding: 0.5rem 2rem 2rem 2rem;
}

.rounded {
  border-radius: 4px;
}

/* Text utils */
.decoration-none {
  text-decoration: none;
}

/* Shadows */
.shadow-1 {
  box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.15); 
}

.shadow-2 {
  box-shadow: 0px 0px 10px 6px #cccc;
}

.shadow-bottom-1 {
  box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.shadow-bottom-2 {
  box-shadow: 0px 10px 10px 6px #cccc;
}

/*  Margins */

.m-0 {
  margin: 0;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mt-3 {
  margin-top: 3rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.ml-1 {
  margin-left: 1rem;
}

.mr-1 {
  margin-right: 1rem;
}

.mt-0 {
  margin-top: 0rem;
}

.mb-0 {
  margin-bottom: 0rem;
}

.ml-0 {
  margin-left: 0rem;
}

.mr-0 {
  margin-right: 0rem;
}

.m-auto {
  margin: auto
}

/* Borders */
.border-0 {
  border: none;
}

.border-inset {
  border: inset;
  border-width: 1px;
}

/* Cursor */
.cursor-pointer {
  cursor: pointer;
}


/* Display */
.d-flex {
  display: flex;
}

.d-inline {
  display: inline;
}

.d-none {
  display: none;
}

.content-end {
  justify-content: end;
}

.text-right {
  text-align: right;
}

/* Responsive */
.row,
.row-lg,
.row-md,
.row-sm {
  display: flex;
  flex-direction: row;
}

.col {
  display: flex;
  flex-direction: column;
}

.wrap {
  flex-wrap: wrap;
}

.grid-item {
  flex-grow: 1;
  /* height: fit-content; */
}

.f-25 {
  flex: 25% 1;
}

.f-50 {
  flex: 50% 1;
}

.f-75 {
  flex: 75% 1;
}

.f-100 {
  flex: 100% 1;
}

.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.w-100 {
  width: 100%;
}

.centered {
  align-items: center;
  justify-content: center;
}

.align-baseline {
  align-items: baseline;
}

.gap-1 {
  grid-gap: 1rem;
  gap: 1rem;
}

.image-gallery-slides {
  border-radius: 25px;
}

.image-gallery-fullscreen-button>.image-gallery-svg {
  height: 35px !important;
  width: 35px !important;
}

.image-gallery-svg {
  height: 70px !important;
  width: 40px !important;
}



@media screen and (max-width: 1200px) {
  .row-lg {
    flex-direction: column;
  }

  .d-none-lg {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .row-md {
    flex-direction: column;
  }

  .d-none-md {
    display: none;
  }

  .image-gallery-fullscreen-button>.image-gallery-svg {
    height: 25px !important;
    width: 25px !important;
  }
  
  .image-gallery-svg {
    height: 60px !important;
    width: 35px !important;
  }
}

@media screen and (max-width: 768px) {
  .row-sm {
    flex-direction: column;
  }

  .d-none-sm {
    display: none;
  }
}

@media screen and (max-width: 576px) {
  .row-xs {
    flex-direction: column;
  }

  .d-none-xs {
    display: none;
  }

  .container {
    padding: 10px;
  }
  .photo{
    border-image-width: auto;
    align-content: center;
  }
}

/* Safari */

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* NotFound.module.css */
.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #696969;
  background-size: cover;
  background-position: center center;
  z-index: 0;
}
.not-found-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #0009;
  z-index: 1;


}
.not-found-logo {
  width: 200px; /* Adjust logo width as needed */
  margin-bottom: 2rem;
  z-index: 2;
}

.not-found-h1 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: var(--orange);
  z-index: 2;
}

.not-found-p {
  font-size: 1.2rem;
  color: #e8e8e8;
  max-width: 720px;
  margin-bottom: 2rem;
  text-align: center;
  z-index: 2;
}

.not-found-link {
  background-color: var(--primary-color);
  color: #fff;
  padding: 1rem 2rem;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.2s ease;
  z-index: 2;
}

.not-found-link:hover {
  background-color: #222;
}


::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: transparent;
  margin-left: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: var(--primary-color);
}
.Filters_filter__cztt8 {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.Filters_filterMobile__3yX8Q {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
}

.Filters_filterMobile__3yX8Q>div {
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 80dvh;
}

.Filters_filterItemsWrapper__3xJED {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 60vh;
}

.Filters_filterMobile__3yX8Q>div {
    flex-wrap: wrap;
}

.Filters_filterItemsWrapper__3xJED>div {
    flex-wrap: wrap;
}

.Filters_divider__vUTPF {
    height: 100px;
    margin: auto 30px;
    width: 1px;
    background: lightgray;
}

.Filters_dividerPage__2hWKX {
    margin: auto 30px;
    width: 1px;
    background: lightgray;
    height: 700px;
}

.Filters_blocksfilter__3ERYY {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    margin-top: 20px;
}

.Filters_textFilter__TSkZ2 {
    margin-bottom: 5px;
    font-weight: 500;
}

.Filters_fBlockFilter__1xlko {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;
}

.Filters_checkboxLabel__3EEQg {
    display: flex;
    align-items: center;
    padding: 6px 7px 6px 7px;
    /* box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.028); */
    margin-bottom: 8px;
    border-radius: 15px;
}

.Filters_checkBoxText__24lWc {
    color: var(--primary-color);
    margin-bottom: 1.5px;
    margin-left: 5px;
}

.Filters_checkboxInput__ErDKe {
    position: relative !important;
    -webkit-appearance: none;
            appearance: none;
    box-sizing: content-box;
    overflow: hidden;
    background: none;
    padding: 0;
    border: none;
}

.Filters_checkboxInput__ErDKe:before {
    content: '';
    display: block;
    box-sizing: content-box;
    width: 16px;
    height: 16px;
    border: 2px solid #ccc;
    transition: 0.2s border-color ease;
}

.Filters_checkboxInput__ErDKe:checked:before {
    border-color: rgb(0, 68, 107);
    transition: 0.5s border-color ease;
}

.Filters_checkboxInput__ErDKe:after {
    content: '';
    display: block;
    position: absolute;
    box-sizing: content-box;
    top: 50%;
    left: 50%;
    transform-origin: 50% 50%;
    background-color: rgb(0, 68, 107);
    width: 16px;
    height: 16px;
    border-radius: 100vh;
    transform: translate(-50%, -50%) scale(0);
}

.Filters_checkboxInput__ErDKe[type="checkbox"]:before {
    border-radius: 16px/4;
}

.Filters_checkboxInput__ErDKe[type="checkbox"]:after {
    width: 9.6px;
    height: 16px;
    border-radius: 0;
    transform: translate(-50%, -85%) scale(0) rotate(45deg);
    background-color: transparent;
    box-shadow: 4px 4px 0px 0px rgb(0, 68, 107);
}

.Filters_checkboxInput__ErDKe[type="checkbox"]:checked:after {
    animation: Filters_toggleOnCheckbox__1s0Nj 0.2s ease forwards;
}

.Filters_checkboxInput__ErDKe[type="checkbox"].Filters_filled__2Qt9G:before {
    border-radius: 16px/4;
    transition: 0.2s border-color ease, 0.2s background-color ease;
}

@keyframes Filters_toggleOnCheckbox__1s0Nj {
    0% {
        opacity: 0;
        transform: translate(-50%, -85%) scale(0) rotate(45deg);
    }

    70% {
        opacity: 1;
        transform: translate(-50%, -85%) scale(0.9) rotate(45deg);
    }

    100% {
        transform: translate(-50%, -85%) scale(0.8) rotate(45deg);
    }
}

.Filters_input-range-wrapper__3bJk2 {
    padding: 6px 10px 14px 10px;
    /* box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.028); */
    margin: 3px 0 5px 0;
    border-radius: 15px;
}

.Filters_slider-text__2ARfC {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.Filters_slider-text__2ARfC>p {
    margin: 0;
    padding: 0;
    font-weight: 500;
    margin: 0 4px 0 4px;
}

.Filters_buttons__wMsu6 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Filters_filterButton__1tRrT {
    padding: 5px;
    color: white;
    background-color: var(--primary-color);
    border-radius: 5px;
    font-size: 20px;
    width: 100px;
    text-align: center;
    margin: 16px 0 10px 0;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
    box-sizing: border-box;
    border: 1px solid var(--primary-color);
}

.Filters_filterButton__1tRrT:hover {
    color: var(--primary-color);
    background-color: #fff;
}

.Filters_resetButton__1fRbB {
    color: rgb(110, 110, 110);
    background: 1px solid rgb(110, 110, 110);
    padding: 5px 15px;
    border: none;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    margin: 5px 0 25px 0;
}


.Filters_tredingButton__2CopZ {
    margin-top: 60px;
    margin-bottom: 10px;
}

.Filters_footerText__3HXzM {
    font-size: 20px;
    margin-bottom: 20px;
}

.Filters_buttonText__18SZz {
    padding: 3px 5px 3px 5px;
    color: rgb(62, 77, 225);
    background-color: rgb(236, 237, 252);
    border: none;
}

.Filters_allButtons__2LUL4 {
    display: flex;
    justify-content: space-between;
    margin-right: 30px;
}

.Filters_buttontext__3c4fg {
    font-size: 14px;
}

.Filters_componentBox__2c3PV {
    margin-top: 15px;
    margin-left: 40px;
    margin-right: 15px;
}

.Filters_priceLabel__30476 {
    margin-left: 5px;
    margin-right: 35px;
}

.Filters_timeFilter__2qxvt {
    margin-right: -20px;
}

.Filters_timeTable__SKilh {
    display: flex;
    width: 80px;
    color: black;
    justify-content: center;
    margin: 4px 6px;
    padding: 1px 1px;
    border-radius: 6px;
    /* margin-left: 10px; */
    border: 1px solid grey;
}

.Filters_selected__aTspc {
    background-color: var(--primary-color);
    color: white;
}

.Filters_scrollMenu__1isV2 {
    display: flex;
    width: 180px;
    min-inline-size: -webkit-fill-available;
    float: left;
    font-size: 13px;
    margin: 2px auto;
    max-width: 800px;
    padding: 1px 1px;
    border-bottom: 1px solid rgb(235, 235, 235);
    overflow: auto;
    white-space: nowrap;
}

/* width */
.Filters_scrollMenu__1isV2::-webkit-scrollbar {
    width: 2px !important;
    height: 3px;
}

/* Handle */
.Filters_scrollMenu__1isV2::-webkit-scrollbar-thumb {
    background: var(--primary-color);
}

.Filters_h1__ZJOID {
    font-size: 22px;
    font-weight: 600;
}

/* Handle on hover */
.Filters_scrollMenu__1isV2::-webkit-scrollbar-thumb:hover {
    background: #555;
}

@media only screen and (max-width: 768px) {
    .Filters_homePageWrapper__2Htp_ {
        margin-left: 5px;
    }

    .Filters_componentBox__2c3PV {
        margin-left: 5px;
    }

    .Filters_scrollMenu__1isV2 {
        width: 110px;
        margin-left: 10px;
    }

    .Filters_checkboxFilter__1IjsE {
        font-size: .9rem;
    }

    .Filters_timeFilter__2qxvt {
        margin-right: 3px;
    }

    .Filters_textFilter__TSkZ2 {
        font-size: .9rem;
    }

    .Filters_resetButton__1fRbB {
        font-size: .8rem;
    }

    .Filters_filterButton__1tRrT {
        font-size: 1rem;
    }
}

@media screen and (max-width: 520px) {
    .Filters_divider__vUTPF {
        display: none;
    }

    .Filters_blocksfilter__3ERYY {
        margin-top: 0;
    }

    .Filters_fBlockFilter__1xlko {
        width: 100%;
        margin: 5px 0;
    }

    .Filters_priceBlockFilter__3ZphF {
        margin-top: 15px;
    }
}
.PriceFilter_range-input__3dLMi {
    position: relative;
    border: none;
}

.PriceFilter_min-range__20fpB,
.PriceFilter_max-range__S0fkA {
    position: absolute;
    width: 100%;
    height: 5px;
    top: -12px;
    z-index: 5;
    pointer-events: none;
    background: none;
    -webkit-appearance: none;
            appearance: none;
    accent-color: var(--primary-color);
    border: none;
    margin-left: 0;
}

.PriceFilter_min-range__20fpB::-webkit-slider-thumb,
.PriceFilter_max-range__S0fkA::-webkit-slider-thumb {
    height: 16px;
    width: 16px;
    background-color: var(--primary-color);
    border-radius: 50%;
    pointer-events: auto;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
    margin-left: -10px;
}

.PriceFilter_slider-container__FLybK {
    height: 6px;
    position: relative;
    background-color: #ddd;
    border-radius: 5px;
    margin-top: 10px;
}

.PriceFilter_progress__3o3VB {
    position: absolute;
    height: 100%;
    background-color: var(--primary-color);
    border-radius: 5px;
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #2c346150;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pop-inner {
  display: flex;
  flex-direction: column;
  margin: 10px;
  position: relative;
  padding: 20px 22px;
  width: 100%;
  max-width: 640px;
  background-color: #FFF;
  border-radius: 10px;
}


.close-icon {
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary-color);
  background-color: #fff;
  border-radius: 5px;
  font-size: 18px;
  text-align: center;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  box-sizing: border-box;
  border: 1px solid var(--primary-color)
}

.close-icon:hover {
  background-color: var(--primary-color);
  color: #fff;
}

.popup-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popup-title {
  font-size: 22px;
  font-weight: 600;
  margin: 0 0 5px 0;
  padding: 0;
  color: var(--primary-color);
}


/* @media screen and (max-width: 700px) {
    .popup {
      width:99%;
    }
} */


/* .popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
  }
  
  .box {
    position: relative;
    width: 70%;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
  }
  
   */
.TimeRangeFilter_timeRangePickerWrapper__1X0_H {
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.TimeRangeFilter_timeRangePicker__1ohI_ {
    padding: 8px 10px;
    font-size: 15px;
    border-radius: 8px;
}

.TimeRangeFilter_timeRangePickerWrapper__1X0_H > p {
    margin: 0 5px;
    padding: 0;
    font-size: 15px;
    font-weight: 500;
}
.Accordion_accordion__388DU {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.Accordion_head__3IsI4 {
    display: flex;
    justify-content: space-between;
    padding: 15px 15px;
    /* border-top-left-radius: 10px;
    border-top-right-radius: 10px; */
}
    

.Accordion_button___jnP8 {
    background-color: #80CDE9;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
}

.Accordion_body__2l9ip {
    padding: 15px 15px;
}

.Accordion_name__2wbW8 {
    margin: 0;
    font-size: 18px;
}

.Accordion_isOpen__3tSCD {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: rgba(51, 71, 168, 0.15);
}
.TimeGallery_arrowWrapper__tMX-t {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.TimeGallery_arrowButton__1XDtL {
    display: flex;
    align-items: center;
    background-color: var(--primary-color);
    border: none;
    border-radius: 50%;
    color: white;
    cursor: pointer;
    font-size: 20px;
    height: 25px;
    width: 25px;
    padding-top: 2.5px; /* FUN */
}

.TimeGallery_prevButton__2zPvG {
    margin-right: 7px;
}

.TimeGallery_nextButton__1ReGp {
    margin-left: 7px;
    padding-left: 7px;
}

.TimeGallery_disabledArrowTime__2J0O0 {
    opacity: 0.5;
}

.TimeGallery_timeDisplay__2wRr4 {
    font-weight: 500;
}

.TimeGallery_timeDisplay__2wRr4 > p {
    margin: 0;
}
.TicketMobile_itemWrapper__2UsYL {
    display: flex;
    align-items: center;
    margin: 10px 0;
    padding: 0 10px;
    background: radial-gradient(circle 10px at right, #0000 97%, white) right,
        radial-gradient(circle 10px at left, #0000 97%, white) left;
    background-size: 51% 100%;
    background-repeat: no-repeat;
    border-radius: 4px;
    filter: drop-shadow(0 0 2px rgb(0 0 0 / 40%));
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.TicketMobile_itemInfo__2Yp5x {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.TicketMobile_imageWrapper__1d-Pg {
    display: flex;
    align-items: center;
    justify-self: center;
    padding: 8px 10px;
}

.TicketMobile_infoWrapper__2CzkM img {
    border-radius: 50%;
    width: 70px;
    height: 70px;
    object-fit: cover;
}

.TicketMobile_pricePerPerson__OTfNZ {
    font-weight: 500;
    font-size: 16px;
    align-self: flex-end;
    padding-right: 10px;
}

.TicketMobile_itemButton__ZfUPP {
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color);
    cursor: pointer;
    color: white;
    border-radius: 5px;
    font-size: 15px;
    padding: 5px 15px;
    transition: all 0.3s ease;
    margin-right: 5px;
    text-wrap: nowrap;
}

.TicketMobile_itemButton__ZfUPP:hover {
    background-color: white;
    color: var(--primary-color);
}

.TicketMobile_ticketInfo__17VB5 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 10px;
    flex-grow: 1;
    background-color: rgb(247, 248, 254);
    padding: 8px 10px;
    z-index: 9999;
}

.TicketMobile_titleBoatTicket__3jv5A {
    font-size: 20px;
    padding: 2px 0;
    color: var(--primary-color);
    font-weight: 500;
    overflow: auto;
    margin-right: 10px;
    word-wrap: break-word;
    word-break: normal;
    max-width: 85%;
}

/* width */
.TicketMobile_titleBoatTicket__3jv5A::-webkit-scrollbar {
    width: 2px !important;
    height: 2px;
}

/* Handle */
.TicketMobile_titleBoatTicket__3jv5A::-webkit-scrollbar-thumb {
    background: var(--primary-color);
}

/* Handle on hover */
.TicketMobile_titleBoatTicket__3jv5A::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.TicketMobile_buttonWrapper__EBQMq {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px 10px;
}

.TicketMobile_buttonTourInfo__3GS9t {
    border-radius: 6px;
    background: var(--primary-color);
    padding: 4px 16px;
    color: white;
    font-size: 12px;
    border: none;
    margin-top: 4px;
}

.TicketMobile_textamenties__3zK3d {
    font-size: 14px;
    color: rgb(175, 175, 175);
}

.TicketMobile_style__2NFko.TicketMobile_starRating__2UkWb {
    font-size: 10px;
}

.TicketMobile_iconList__3O6Gt,
.TicketMobile_iconListChild__ct4oQ {
    display: flex;
    align-items: center;
    margin-top: 3px;
}

.TicketMobile_iconListCalendar__2IfFX {
    margin-top: 0;
    margin-right: 10px;
}

.TicketMobile_iconListChild__ct4oQ {
    margin-left: 15px;
}

.TicketMobile_iconListChild__ct4oQ>i,
.TicketMobile_iconList__3O6Gt>i,
.TicketMobile_iconListCalendar__2IfFX>i {
    margin-right: 5px;
}

.TicketMobile_iconListItem__31sA3 {
    border-radius: 8px;
    color: var(--primary-color);
}

.TicketMobile_startDate__3gx_g {
    display: inline-flex;
    color: grey;
}

.TicketMobile_peopleNumber__1AS2P {
    color: grey;
    margin: 2px 0 0 5px;
}

.TicketMobile_startTripDate__sMkhg {
    color: grey;
    margin-top: 2px;
}

.TicketMobile_moreInfoWrapper__3Sc3l {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 300px;
    flex-wrap: wrap;
}


.TicketMobile_infoWrapper__2CzkM {
    display: flex;
    align-self: stretch;
    width: 100%;
    align-items: center;
}

.TicketMobile_infoTag__1vd_0 {
    border-radius: 6px;
    border: 1px solid var(--primary-color);
    padding: 2px 12px;
    color: var(--primary-color);
    font-size: 12px;
    text-align: center;
    margin-left: 5px;
}

.TicketMobile_iconWrapper__2BUGG,
.TicketMobile_depAndTime__mneRY {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    justify-content: space-between;
}

.TicketMobile_depAndTime__mneRY {
    align-items: center;
    justify-content: center;
    width: 50%;
}

.TicketMobile_peopleList__LMsLC {
    display: flex;
    align-items: center;
}

.TicketMobile_depCity__ALZy5 {
    font-size: 16px;
    color: rgb(168, 174, 240);
    font-weight: 500;
    margin: 0;
    padding: 0;
}

.TicketMobile_timeOfDep__siKwH {
    color: gray;
    margin: 0;
}

.TicketMobile_timeOfDepNoPlace__2Hrpy {
    color: rgb(168, 174, 240);
    margin: 0;
    font-weight: 500;
}

.TicketMobile_topInfo__1ciBc,
.TicketMobile_topInfoTitle__7DNDB {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.TicketMobile_topInfo__1ciBc>div {
    margin-top: 10px;
}

.TicketMobile_scrollMenu__2DuhN {
    display: flex;
    width: 190px;
    min-inline-size: -webkit-fill-available;
    float: left;
    font-size: 13px;
    margin: 2px auto;
    margin-right: 10px;
    max-width: 800px;
    padding: 1px 1px;
    border-bottom: 1px solid rgb(235, 235, 235);
    overflow: auto;
    white-space: nowrap;
}

/* width */
.TicketMobile_scrollMenu__2DuhN::-webkit-scrollbar {
    width: 2px !important;
    height: 3px;
}

/* Handle */
.TicketMobile_scrollMenu__2DuhN::-webkit-scrollbar-thumb {
    background: var(--primary-color);
}

/* Handle on hover */
.TicketMobile_scrollMenu__2DuhN::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.TicketMobile_mainWrapepr__Udo_t {
    display: flex;
    flex-grow: 1;
}

.TicketMobile_timeTable__1ikNW {
    display: flex;
    width: 80px;
    color: black;
    justify-content: center;
    margin: 4px 6px;
    padding: 1px 1px;
    border-radius: 6px;
    margin-right: 10px;
    border: 1px solid grey;
}

.TicketMobile_selected__nquwk {
    background-color: var(--primary-color);
    color: white;
}

.TicketMobile_boatImageTicket__IptoX {
    margin-left: 13px;
}

@media only screen and (max-width: 576px) {
    .TicketMobile_moreInfoWrapper__3Sc3l {
        width: 100%;
    }

    .TicketMobile_itemWrapper__2UsYL {
        width: 100%;
        padding: 0;
        margin: 10px 0;
        align-items: center;
        justify-content: center;
    }
}


@media only screen and (max-width: 395px) {
    .TicketMobile_boatImageTicket__IptoX {
        display: none;
    }

    .TicketMobile_ticketInfo__17VB5 {
        background: #fff;
    }
}
.Ticket_itemWrapper__2QrFt {
    display: flex;
    align-items: center;
    margin: 10px 0;
    padding: 0 10px;
    background: radial-gradient(circle 10px at right, #0000 97%, white) right,
        radial-gradient(circle 10px at left, #0000 97%, white) left;
    background-size: 51% 100%;
    background-repeat: no-repeat;
    border-radius: 4px;
    filter: drop-shadow(0 0 2px rgb(0 0 0 / 40%));
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.Ticket_itemInfo__1OTX0 {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Ticket_imageWrapper__3c89t {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8px 10px;
}

.Ticket_imageWrapper__3c89t img {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    object-fit: cover;
}

.Ticket_pricePerPerson__eibNQ {
    font-size: 15px;
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 16px;
    align-self: flex-end;
}

.Ticket_itemButton__AdNMW {
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color);
    cursor: pointer;
    color: white;
    border-radius: 5px;
    font-size: 15px;
    padding: 10px 15px;
    transition: all 0.3s ease;
}

.Ticket_itemButton__AdNMW:hover {
    background-color: white;
    color: var(--primary-color);
}

.Ticket_ticketInfo__2ETUx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 9999;
}

.Ticket_title__XXWW_ {
    font-size: 20px;
    padding: 2px 0;
    color: var(--primary-color);
    font-weight: 500;
    overflow: auto;
    white-space: nowrap;
    text-wrap: wrap;
    max-width: 65%;
}

/* width */
.Ticket_title__XXWW_::-webkit-scrollbar {
    width: 2px !important;
    height: 2px;
}

/* Handle */
.Ticket_title__XXWW_::-webkit-scrollbar-thumb {
    background: var(--primary-color);
}

/* Handle on hover */
.Ticket_title__XXWW_::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.Ticket_buttonWrapper__3qZax {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
}

.Ticket_buttonTourInfo__2z2zt {
    border-radius: 6px;
    background: var(--primary-color);
    padding: 4px 13px;
    color: white;
    font-size: 12px;
    border: none;
    margin-top: 9px;
}

.Ticket_textamenties__3wHSU {
    font-size: 14px;
    color: rgb(175, 175, 175);
}

.Ticket_style__1YEmh.Ticket_starRating__1FuHg {
    font-size: 10px;
}

.Ticket_iconList__RQz_l,
.Ticket_iconListChild__1zZe2,
.Ticket_iconListCalendar__DgdTU {
    display: flex;
    align-items: center;
    margin-top: 3px;
}

.Ticket_iconListCalendar__DgdTU {
    margin-bottom: 10px;
}

.Ticket_iconListChild__1zZe2 {
    margin-left: 15px;
}

.Ticket_iconListChild__1zZe2>i,
.Ticket_iconList__RQz_l>i,
.Ticket_iconListCalendar__DgdTU>i {
    margin-right: 5px;
}

.Ticket_iconListItem__1_SOh {
    border-radius: 8px;
    color: var(--primary-color);
}

.Ticket_startDate__i3C-w {
    display: inline-flex;
    color: grey;
    margin-left: 5px;
}

.Ticket_peopleNumber__3I2Wd {
    color: grey;
    margin: 2px 0 0 5px;
}

.Ticket_startTripDate__1wlX9 {
    color: grey;
    margin-top: 2px;
}

.Ticket_moreInfoWrapper__15sTE {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 300px;
}


.Ticket_infoWrapper__1e66H {
    background-color: rgb(247, 248, 254);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: stretch;
    padding: 8px 10px;
    max-width: 300px;
}

.Ticket_infoTag__1oo7U {
    border-radius: 6px;
    border: 1px solid var(--primary-color);
    padding: 2px 12px;
    color: var(--primary-color);
    font-size: 12px;
    text-align: center;
    margin-left: 5px;
}

.Ticket_iconWrapper__3_B5U,
.Ticket_depAndTime__294-L {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    justify-content: space-between;
}

.Ticket_depAndTime__294-L {
    align-items: center;
    justify-content: center;
    width: 50%;
}

.Ticket_peopleList__1mmWf {
    display: flex;
    align-items: center;
}

.Ticket_depAndTime__294-L>p {
    padding: 0;
}

.Ticket_depCity__3PA5p {
    font-size: 18px;
    color: rgb(168, 174, 240);
    font-weight: 500;
    margin: 0 0 10px 0;
}

.Ticket_timeOfDep__1-13N {
    color: grey;
    margin: 0;
}

.Ticket_scrollMenu__k1V8E {
    display: flex;
    width: 190px;
    min-inline-size: -webkit-fill-available;
    float: left;
    font-size: 13px;
    margin: 2px auto;
    margin-right: 10px;
    max-width: 800px;
    padding: 1px 1px;
    border-bottom: 1px solid rgb(235, 235, 235);
    overflow: auto;
    white-space: nowrap;
}

/* width */
.Ticket_scrollMenu__k1V8E::-webkit-scrollbar {
    width: 2px !important;
    height: 3px;
}

/* Handle */
.Ticket_scrollMenu__k1V8E::-webkit-scrollbar-thumb {
    background: var(--primary-color);
}

/* Handle on hover */
.Ticket_scrollMenu__k1V8E::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.Ticket_mainWrapepr__153nc {
    display: flex;
}

.Ticket_timeTable__1EFNn {
    display: flex;
    width: 80px;
    color: black;
    justify-content: center;
    margin: 4px 6px;
    padding: 1px 1px;
    border-radius: 6px;
    margin-right: 10px;
    border: 1px solid grey;
}

.Ticket_selected__2KkYW {
    background-color: var(--primary-color);
    color: white;
}

.Ticket_infoTicketButton__3K0E3 {
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color);
    cursor: pointer;
    color: white;
    border-radius: 5px;
    font-size: 13px;
    padding: 2px 10px;
}

@media only screen and (max-width: 576px) {
    .Ticket_moreInfoWrapper__15sTE {
        width: 100%;
    }

    .Ticket_itemWrapper__2QrFt {
        width: 100%;
        margin: 0;
        padding: 0;
    }
}
.SearchListing_recentListings__3837P {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
}

.SearchListing_loader__2jI4Q {
  border: 8px solid rgba(63, 143, 201, 0.1);
  border-radius: 50%;
  border-top: 10px solid var(--primary-color);
  width: 60px;
  height: 60px;
  animation: SearchListing_spin__2IJeF 2s linear infinite;
}

.SearchListing_searchListingLoader__sSi0Y {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.SearchListing_popupNoTripsText__2_TM6 > a {
  color: var(--primary-color);
  font-weight: 600;
}

/* Safari */

@keyframes SearchListing_spin__2IJeF {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.RecommendedItem_itemWrapper__35Zyy {
  grid-gap: 6px;
  gap: 6px;
  margin-bottom: 10px;
  max-width: 20rem;
  width: 20rem;
  padding: 10px;
}

.RecommendedItem_cardLink__x2V5s {
  text-decoration: none;
  color: black;
}

.RecommendedItem_itemImage__3Q3zb {
  width: 100%;
  border-radius: 18px;
  height: 200px;
  object-fit: cover;
}

.RecommendedItem_itemDesc__3Na_L {
  text-align: justify;
  text-justify: inter-word;
  font-size: 14px;
}

.RecommendedItem_itemOtherInfo__34zil {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3px;
}

.RecommendedItem_itemAmenities__1tvL5 i {
  margin: 6px;
  background: var(--primary-color);
  color: white;
  border-radius: 50%;
  font-size: 10px;
  padding: 8px;
  width: 12px;
  height: 12px;
}


.RecommendedItem_amenityIconRecommended__3Q6Uw {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-color);
  border-radius: 10px;
  width: 18px;
  height: 18px;
  padding: 7px;
  box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.15);
}

.RecommendedItem_itemIconsInfo__3Je8Q {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 15px;
}

.RecommendedItem_itemAmenities__1tvL5 {
  display: flex;
  align-items: center;
  text-align: center;
}

.RecommendedItem_itemPrice__3rJ2I {
  font-size: 20px;
}

.RecommendedItem_itemImage__3Q3zb {
  width: 100%;
  border-radius: 18px;
  height: 200px;
  object-fit: cover;
}

.RecommendedItem_itemInfo__qxLHQ {
  margin: 5px 0 10px 0;
  padding: 0;
}

.RecommendedItem_itemTitle__1Rhmw {
  font-size: 20px;
  font-weight: 500;
  color: var(--primary-color)
}

.RecommendedItem_itemOtherInfo__34zil {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6px;
}

.RecommendedItem_itemPrice__3rJ2I {
  font-size: 18px;
}

.RecommendedItem_infoTag__6Mxbu {
  width: auto;
  float: right;
  border-radius: 12px;
  background: var(--primary-color);
  padding: 3px 12px;
  color: #fff;
  font-size: 14px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .RecommendedItem_itemWrapper__35Zyy {
    margin-bottom: 50px
  }
}
.SearchBanner_bannerWrapper__1rpN8 {
  background-color: rgb(242, 243, 254);
  border-radius: 20px;
  padding: 15px 10px;
  overflow: auto;
  max-height: 80vh;
}

.SearchBanner_topText__1h_QH {
  display: flex;
  justify-content: space-between;
}

.SearchBanner_text__21wYW {
  font-size: 18px;
  font-weight: 600;
}

.SearchBanner_buttons__1KIoi {
  display: flex;
}

.SearchBanner_noResult__6u-1s {
  margin-top: 10px;
}

.SearchBanner_noResultText__2ZokY {
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 5px 0;
  color: var(--primary-color);
}

.SearchBanner_noResultSubText__2ZVnO {
  font-size: 16px;
  font-weight: 600;
  color: rgb(110, 110, 110);
  margin-bottom: 3px;
}


@media screen and (max-width: 768px) {
	.SearchBanner_bannerWrapper__1rpN8 {
		margin: 0 15px 20px 15px !important;
		max-height: 80vh;
		padding: 20px 5px;
    max-height: unset;
	}

  .SearchBanner_bannerWrapper__1rpN8 > div {
    justify-content: center;
  }
}

.SearchListPage_pageWrapper__1QivR {
	display: flex;
	overflow: hidden;
}

.SearchListPage_searchPageWrapper__1Sl7d {
	display: flex;
	flex-wrap: nowrap;
	min-height: 100vh;
	overflow-x: hidden;
}

.SearchListPage_contentWrapper__8fD1C {
	max-height: 100vh;
	min-height: 100vh;
	width: 100%;
	overflow: auto;
}

.SearchListPage_divider__D_dZB {
	height: 100px;
	margin: auto 30px;
	width: 1px;
	background: lightgray;
}

.SearchListPage_dividerPage__3jAEA {
	margin: auto 30px;
	width: 1px;
	background: lightgray;
	height: 700px;
}

.SearchListPage_wrapper__sSai6 {
	display: flex;
	flex-direction: column;
	width: 40%;
}

.SearchListPage_searchHeaderWrapper__2Fpzg {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 35px 0 20px 0;
	padding: 0 15px;
}

.SearchListPage_searchHeaderMobile__aMQ2J {
	display: flex;
	flex-direction: column;
	margin: 35px 0 20px 0;
	padding: 0 15px;
}

.SearchListPage_filterWrapper__FS-Hn {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.SearchListPage_orderList__3JboB {
	padding: 8px 13px;
	border-radius: 10px;
	font-size: 15px;
	background: none;
	border-color: var(--primary-color);
}

.SearchListPage_filterIcons__3-Ski {
	margin-left: 5px;
	margin-bottom: 2px;
}

.SearchListPage_filtersButton__3NCFr:hover {
	background-color: var(--primary-color);
	color: #fff;
}

.SearchListPage_filter__3XoAM {
	margin-top: 20px;
	box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.09);
	display: flex;
	flex-direction: column;
	align-items: center;
}

.SearchListPage_blocksfilter__hJkE2 {
	display: flex;
	justify-content: space-around;
	margin-top: 20px;
	grid-gap: 35px;
	gap: 35px;

}

.SearchListPage_checkboxFilter__1jrMk {
	color: var(--primary-color);
	margin: 5px 0 5px 0;
	box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.06);
}

.SearchListPage_chechboxes__1meV1 {
	display: flex;
	flex-direction: column;
}

.SearchListPage_buttons__33CI8 {
	display: flex;
	align-items: center;
}

.SearchListPage_filterButton__1tYaU {
	width: 45%;
	padding: 8px 13px;
	border-radius: 10px;
	font-size: 15px;
	background: none;
	outline: none;
	border: 1px solid var(--primary-color);
}

.SearchListPage_resetButton__2mWg- {
	padding: 7px;
	color: var(--primary-color);
	background-color: white;
	border-radius: 5px;
	font-size: 15px;
	width: 100px;
	text-align: center;
	margin: 20px 0;
	margin-right: 30px;
	border: 1px solid var(--primary-color);
	cursor: pointer;
}

.SearchListPage_tredingButton__1f8_t {
	margin-top: 60px;
	margin-bottom: 10px;
}

.SearchListPage_footerText__2B-K- {
	font-size: 20px;
	margin-bottom: 20px;
}

.SearchListPage_buttonText__3eUl_ {
	padding: 3px 5px 3px 5px;
	color: rgb(62, 77, 225);
	background-color: rgb(236, 237, 252);
	border: none;
}

.SearchListPage_allButtons__3ddnH {
	display: flex;
	justify-content: space-between;
	margin-right: 30px;
}

.SearchListPage_buttontext__3gW6D {
	font-size: 14px;
}

.SearchListPage_componentBoxSearch__2-xLT {
	margin: 0 25px 15px 25px;
	width: 50%;
	animation: SearchListPage_w35__2Gzos 0.4s ease forwards;
}

.SearchListPage_componentBoxElements__uhx4T {
	margin: 0 0 15px 15px;
	width: 50%;
	animation: SearchListPage_w65__D3cvI 0.4s ease forwards;
	border-left: 1px solid rgb(242, 243, 254);
	padding: 0 15px;
}

.SearchListPage_priceLabel__2YCi0 {
	margin-left: 5px;
	margin-right: 35px;
}

.SearchListPage_timeFilter__21SgD {
	margin-right: -20px;
}

.SearchListPage_timeTable__v29fs {
	display: flex;
	width: 80px;
	color: black;
	justify-content: center;
	margin: 4px 6px;
	padding: 1px 1px;
	border-radius: 6px;
	/* margin-left: 10px; */
	border: 1px solid grey;
}

.SearchListPage_selected__2w1ZG {
	background-color: var(--primary-color);
	color: white;
}

.SearchListPage_scrollMenu__2--f6 {
	display: flex;
	width: 180px;
	min-inline-size: -webkit-fill-available;
	float: left;
	font-size: 13px;
	margin: 2px auto;
	max-width: 800px;
	padding: 1px 1px;
	border-bottom: 1px solid rgb(235, 235, 235);
	overflow: auto;
	white-space: nowrap;
}

/* width */
.SearchListPage_scrollMenu__2--f6::-webkit-scrollbar {
	width: 2px !important;
	height: 3px;
}

/* Handle */
.SearchListPage_scrollMenu__2--f6::-webkit-scrollbar-thumb {
	background: var(--primary-color);
}

.SearchListPage_h1__1Nmg8 {
	margin-top: 1px;
	color: var(--primary-color);
	margin-bottom: 1px;
	font-weight: 500;
	font-size: 25px;
}

/* Handle on hover */
.SearchListPage_scrollMenu__2--f6::-webkit-scrollbar-thumb:hover {
	background: #555;
}


@keyframes SearchListPage_w35__2Gzos {
	from {
		width: 50%;
	}

	to {
		width: 25%;
	}
}

@keyframes SearchListPage_w65__D3cvI {
	from {
		width: 50%;
	}

	to {
		width: 75%;
	}
}


@media screen and (max-width: 768px) {
	.SearchListPage_componentBoxSearch__2-xLT {
		width: 100%;
		margin: 0;
		padding: 0;
		animation: none !important;
	}

	.SearchListPage_componentBoxElements__uhx4T {
		animation: none !important;
		width: 100%;
		margin: 0;
		padding: 0;
	}
}
