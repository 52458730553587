.homePageWrapper {
  display: flex;
	flex-wrap: nowrap;
	min-height: 100vh;
}

.pageWrapper {
	display: flex;
	overflow: hidden;
}

.contentWrapper {
	max-height: 100vh;
  min-height: 100vh;
	width: 100%;
	overflow: auto;
}

.componentBoxSearch {
  margin: 0 25px 15px 25px;
  width: 50%;
}

.recommendedMain {
  margin-top: 70px;
}

.fHome {
  display: flex;
  flex-grow: 1;
  flex-basis: 50%;
}

.p {
  margin-top: 1px;
  color: var(--primary-color);
  margin-bottom: 1px;
  font-weight: 500;
  font-size: 25px;
}



/* Home Card */
.wrapper {
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.search {
  border: transparent;
  background-color: rgb(235, 237, 254);
  border-radius: 10px;
  flex-grow: 1;
  padding: 15px;
  margin-bottom: 20px;
}

.p {
  margin-top: 1px;
  color: var(--primary-color);
  margin-bottom: 1px;
  font-weight: 500;
  font-size: 30px;
}

.buttonsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin: 20px 0;
}

.button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  color: rgb(190, 190, 190);
  cursor: pointer;
  margin: 5px 0;
  border: 1px solid transparent;
}

.activeButton {
  background-color: rgb(62, 113, 143);
  color: white;
  border: 1px solid white;
  border-radius: 50%;
  box-shadow: 4px 4px 6px 2px rgba(0, 0, 0, 0.28);
}

.buttonText {
  margin-top: 6px;
  color: grey;
}

.activeButtonText {
  color: black;
}

.fas {
  font-size: 20px;
}

.configuration {
  margin-top: 10px;
  display: flex;
  gap: 16px;
}

.input {
  background-color: rgb(242, 243, 254);
  border: 1px solid #e2e5fe;
  padding: 10px;
}

.datePicker {
  width: 50%;
}

.groupSelect {
  width: 50%;
}

.searchbtn {
  padding: 15px;
  color: white;
  background-color: var(--primary-color);
  border-radius: 5px;
  margin: 0 auto;
  font-size: 17px;
  width: 200px;
  margin-top: 15px;
  text-align: center;
  text-decoration: none;
}

.recent {
  border: 1px solid;
}

.recentSearches {
  font-weight: 700;
  color: black;
}

.wrapper {
  padding-top: 35px;
  margin-top: 0px;
  /* margin-left: 40px;
  margin-right: 40px; */
  display: flex;
  flex-direction: column;
  /* flex-grow: 1; */
}


.search {
  border: transparent;
  background-color: rgb(235, 237, 254);
  border-radius: 10px;
  /* flex-grow: 1;	 */
  padding: 15px;
  margin-bottom: 20px;
}

.h1 {
  margin-top: 1px;
  color: var(--primary-color);
  margin-bottom: 1px;
  font-weight: 500;
  font-size: 25px;
}

.p {
  margin-top: 1px;
  color: var(--primary-color);
  margin-bottom: 1px;
  font-weight: 500;
  font-size: 20px;
}

.buttonsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin: 20px 0;
}

.button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  color: rgb(190, 190, 190);
  cursor: pointer;
  margin: 5px 0;
  border: 1px solid transparent;
}

.activeButton {
  background-color: rgb(62, 113, 143);
  color: white;
  border: 1px solid white;
  border-radius: 50%;
  box-shadow: 4px 4px 6px 2px rgba(0, 0, 0, 0.28);
}

.buttonText {
  margin-top: 6px;
  color: grey;
}

.activeButtonText {
  color: black;
}

.fas {
  font-size: 20px;
}

.configuration {
  margin-top: 10px;
  display: flex;
  /* flex-wrap: wrap; */
  gap: 16px;
}

.input {
  background-color: rgb(242, 243, 254);
  border: 1px solid #e2e5fe;
  padding: 10px;
  text-align: center;
}

.align_baseline {
  align-items: baseline;
}

.d_flex {
  display: flex;
}

.datePicker {
  width: 50%;
}

.groupSelect {
  width: 50%;
}

.searchbtn {
  padding: 15px;
  color: white;
  background-color: var(--primary-color);
  border-radius: 5px;
  margin: 0 auto;
  font-size: 17px;
  width: 200px;
  margin-top: 15px;
  text-align: center;
  text-decoration: none;
}

.disableBtn {
  cursor: not-allowed;
}


@media screen and (max-width: 768px) {
  .datePicker {
    width: calc(100% - 25px) !important;
  }

  .componentBoxSearch {
    width: 100%;
    margin: 0;
  }
}

/* .number {
  background-color: rgb(242, 243, 254);
  border: 1px solid #e2e5fe;
  padding: 10px 18px;
  text-align: center;
  font-size: 12px;
} */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance: textfield;
  /* Firefox */
}