.timeRangePickerWrapper {
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.timeRangePicker {
    padding: 8px 10px;
    font-size: 15px;
    border-radius: 8px;
}

.timeRangePickerWrapper > p {
    margin: 0 5px;
    padding: 0;
    font-size: 15px;
    font-weight: 500;
}