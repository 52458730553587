
.wrapper {
  margin-top: 35px;
  display: flex;
  flex-direction: column;
}

.search {
  border: transparent;
  background-color: rgb(235, 237, 254);
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
}

.h1 {
  margin-top: 1px;
  color: var(--primary-color);
  margin-bottom: 1px;
  font-weight: 500;
  font-size: 25px;
}
.p {
  margin-top: 1px;
  color: var(--primary-color);
  margin-bottom: 1px;
  font-weight: 500;
  font-size: 20px;
}

.buttonsWrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 50px;
  margin: 20px 0;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contentWrapper:hover .buttonText {
  color: var(--text-hover-color);
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f3f4f6;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.button i {
  font-size: 18px;
  transition: color 0.2s ease-in-out;
}

.button:hover i {
  color: white;
}

.button:hover {
  background-color: var(--icon-hover-color);
}

.activeButton {
  background-color: var(--icon-hover-color);
  color: white;
}

.activeButton i {
  color: white;
}

.disableBtn {
  opacity: 0.5;
  cursor: not-allowed;
}

.buttonText {
  margin-top: 8px;
  font-size: 14px;
  font-weight: 600;
  transition: color 0.3s ease;
}

.activeButtonText {
  color: var(--text-hover-color);
  font-weight: 600;
}


.fas {
  font-size: 20px;
}

.searchPeopleWrapper {
  flex-wrap: wrap;
}

.cityLists {
  padding: 13px;
  background: none;
  border-color: rgb(165, 172, 242);
}

.configuration {
  margin-top: 10px;
  display: flex;
  /* flex-wrap: wrap; */
  gap: 16px;
}

.input {
  background-color: rgb(242, 243, 254);
  border: 1px solid #e2e5fe;
  padding: 10px;
  text-align: center;
}

.align_baseline {
  align-items: baseline;
}

.d_flex {
  display: flex;
}

.datePicker {
  width: 50%;
}

.groupSelect {
  width: 50%;
}

.searchbtn {
  padding: 15px;
  color: white;
  background-color: var(--primary-color);
  border-radius: 5px;
  margin: 0 auto;
  font-size: 17px;
  width: 200px;
  margin-top: 15px;
  text-align: center;
  text-decoration: none;
}

.disableBtn {
  cursor: not-allowed;
}

.inlineIcon {
  height: 15px;
  margin-left: 10px;
}

.borderLeftNone {
  border-left: none;
}

.borderRightNone {
  border-right: none;
}

@media screen and (max-width: 768px) {
  .datePicker {
    width: calc(100% - 25px) !important;
  }

  .wrapper {
    margin: 35px 20px 0 20px;
  }
}

.plusMinus {
  align-items: center;
  background-color: #fff;
  border-color: #727483;
  border-radius: 2500rem;
  border-style: solid;
  border-width: 1px;
  display: flex;
  height: 2rem;
  justify-content: center;
  width: 2rem;
  cursor: pointer;
}

.logo {
  color: #727483;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

.formInput {
  background-color: white;
  border: none;
  text-align: center;
  height: 15px;
  width: 15px;
  outline: none;
}
