.pageWrapper {
	display: flex;
	overflow: hidden;
}

.searchPageWrapper {
	display: flex;
	flex-wrap: nowrap;
	min-height: 100vh;
	overflow-x: hidden;
}

.contentWrapper {
	max-height: 100vh;
	min-height: 100vh;
	width: 100%;
	overflow: auto;
}

.divider {
	height: 100px;
	margin: auto 30px;
	width: 1px;
	background: lightgray;
}

.dividerPage {
	margin: auto 30px;
	width: 1px;
	background: lightgray;
	height: 700px;
}

.wrapper {
	display: flex;
	flex-direction: column;
	width: 40%;
}

.searchHeaderWrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 35px 0 20px 0;
	padding: 0 15px;
}

.searchHeaderMobile {
	display: flex;
	flex-direction: column;
	margin: 35px 0 20px 0;
	padding: 0 15px;
}

.filterWrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.orderList {
	padding: 8px 13px;
	border-radius: 10px;
	font-size: 15px;
	background: none;
	border-color: var(--primary-color);
}

.filterIcons {
	margin-left: 5px;
	margin-bottom: 2px;
}

.filtersButton:hover {
	background-color: var(--primary-color);
	color: #fff;
}

.filter {
	margin-top: 20px;
	box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.09);
	display: flex;
	flex-direction: column;
	align-items: center;
}

.blocksfilter {
	display: flex;
	justify-content: space-around;
	margin-top: 20px;
	gap: 35px;

}

.checkboxFilter {
	color: var(--primary-color);
	margin: 5px 0 5px 0;
	box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.06);
}

.chechboxes {
	display: flex;
	flex-direction: column;
}

.buttons {
	display: flex;
	align-items: center;
}

.filterButton {
	width: 45%;
	padding: 8px 13px;
	border-radius: 10px;
	font-size: 15px;
	background: none;
	outline: none;
	border: 1px solid var(--primary-color);
}

.resetButton {
	padding: 7px;
	color: var(--primary-color);
	background-color: white;
	border-radius: 5px;
	font-size: 15px;
	width: 100px;
	text-align: center;
	margin: 20px 0;
	margin-right: 30px;
	border: 1px solid var(--primary-color);
	cursor: pointer;
}

.tredingButton {
	margin-top: 60px;
	margin-bottom: 10px;
}

.footerText {
	font-size: 20px;
	margin-bottom: 20px;
}

.buttonText {
	padding: 3px 5px 3px 5px;
	color: rgb(62, 77, 225);
	background-color: rgb(236, 237, 252);
	border: none;
}

.allButtons {
	display: flex;
	justify-content: space-between;
	margin-right: 30px;
}

.buttontext {
	font-size: 14px;
}

.componentBoxSearch {
	margin: 0 25px 15px 25px;
	width: 50%;
	animation: w35 0.4s ease forwards;
}

.componentBoxElements {
	margin: 0 0 15px 15px;
	width: 50%;
	animation: w65 0.4s ease forwards;
	border-left: 1px solid rgb(242, 243, 254);
	padding: 0 15px;
}

.priceLabel {
	margin-left: 5px;
	margin-right: 35px;
}

.timeFilter {
	margin-right: -20px;
}

.timeTable {
	display: flex;
	width: 80px;
	color: black;
	justify-content: center;
	margin: 4px 6px;
	padding: 1px 1px;
	border-radius: 6px;
	/* margin-left: 10px; */
	border: 1px solid grey;
}

.selected {
	background-color: var(--primary-color);
	color: white;
}

.scrollMenu {
	display: flex;
	width: 180px;
	min-inline-size: -webkit-fill-available;
	float: left;
	font-size: 13px;
	margin: 2px auto;
	max-width: 800px;
	padding: 1px 1px;
	border-bottom: 1px solid rgb(235, 235, 235);
	overflow: auto;
	white-space: nowrap;
}

/* width */
.scrollMenu::-webkit-scrollbar {
	width: 2px !important;
	height: 3px;
}

/* Handle */
.scrollMenu::-webkit-scrollbar-thumb {
	background: var(--primary-color);
}

.h1 {
	margin-top: 1px;
	color: var(--primary-color);
	margin-bottom: 1px;
	font-weight: 500;
	font-size: 25px;
}

/* Handle on hover */
.scrollMenu::-webkit-scrollbar-thumb:hover {
	background: #555;
}


@keyframes w35 {
	from {
		width: 50%;
	}

	to {
		width: 25%;
	}
}

@keyframes w65 {
	from {
		width: 50%;
	}

	to {
		width: 75%;
	}
}


@media screen and (max-width: 768px) {
	.componentBoxSearch {
		width: 100%;
		margin: 0;
		padding: 0;
		animation: none !important;
	}

	.componentBoxElements {
		animation: none !important;
		width: 100%;
		margin: 0;
		padding: 0;
	}
}