.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #2c346150;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pop-inner {
  display: flex;
  flex-direction: column;
  margin: 10px;
  position: relative;
  padding: 20px 22px;
  width: 100%;
  max-width: 640px;
  background-color: #FFF;
  border-radius: 10px;
}


.close-icon {
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary-color);
  background-color: #fff;
  border-radius: 5px;
  font-size: 18px;
  text-align: center;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  box-sizing: border-box;
  border: 1px solid var(--primary-color)
}

.close-icon:hover {
  background-color: var(--primary-color);
  color: #fff;
}

.popup-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popup-title {
  font-size: 22px;
  font-weight: 600;
  margin: 0 0 5px 0;
  padding: 0;
  color: var(--primary-color);
}


/* @media screen and (max-width: 700px) {
    .popup {
      width:99%;
    }
} */


/* .popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
  }
  
  .box {
    position: relative;
    width: 70%;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
  }
  
   */