.itemWrapper {
  gap: 6px;
  margin-bottom: 10px;
  max-width: 20rem;
  width: 20rem;
  padding: 10px;
}

.cardLink {
  text-decoration: none;
  color: black;
}

.itemImage {
  width: 100%;
  border-radius: 18px;
  height: 200px;
  object-fit: cover;
}

.itemDesc {
  text-align: justify;
  text-justify: inter-word;
  font-size: 14px;
}

.itemOtherInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3px;
}

.itemAmenities i {
  margin: 6px;
  background: var(--primary-color);
  color: white;
  border-radius: 50%;
  font-size: 10px;
  padding: 8px;
  width: 12px;
  height: 12px;
}


.amenityIconRecommended {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-color);
  border-radius: 10px;
  width: 18px;
  height: 18px;
  padding: 7px;
  box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.15);
}

.itemIconsInfo {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.itemAmenities {
  display: flex;
  align-items: center;
  text-align: center;
}

.itemPrice {
  font-size: 20px;
}

.itemImage {
  width: 100%;
  border-radius: 18px;
  height: 200px;
  object-fit: cover;
}

.itemInfo {
  margin: 5px 0 10px 0;
  padding: 0;
}

.itemTitle {
  font-size: 20px;
  font-weight: 500;
  color: var(--primary-color)
}

.itemOtherInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6px;
}

.itemPrice {
  font-size: 18px;
}

.infoTag {
  width: auto;
  float: right;
  border-radius: 12px;
  background: var(--primary-color);
  padding: 3px 12px;
  color: #fff;
  font-size: 14px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .itemWrapper {
    margin-bottom: 50px
  }
}