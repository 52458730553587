.filter {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.filterMobile {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
}

.filterMobile>div {
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 80dvh;
}

.filterItemsWrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 60vh;
}

.filterMobile>div {
    flex-wrap: wrap;
}

.filterItemsWrapper>div {
    flex-wrap: wrap;
}

.divider {
    height: 100px;
    margin: auto 30px;
    width: 1px;
    background: lightgray;
}

.dividerPage {
    margin: auto 30px;
    width: 1px;
    background: lightgray;
    height: 700px;
}

.blocksfilter {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    margin-top: 20px;
}

.textFilter {
    margin-bottom: 5px;
    font-weight: 500;
}

.fBlockFilter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;
}

.checkboxLabel {
    display: flex;
    align-items: center;
    padding: 6px 7px 6px 7px;
    /* box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.028); */
    margin-bottom: 8px;
    border-radius: 15px;
}

.checkBoxText {
    color: var(--primary-color);
    margin-bottom: 1.5px;
    margin-left: 5px;
}

.checkboxInput {
    position: relative !important;
    appearance: none;
    box-sizing: content-box;
    overflow: hidden;
    background: none;
    padding: 0;
    border: none;
}

.checkboxInput:before {
    content: '';
    display: block;
    box-sizing: content-box;
    width: 16px;
    height: 16px;
    border: 2px solid #ccc;
    transition: 0.2s border-color ease;
}

.checkboxInput:checked:before {
    border-color: rgb(0, 68, 107);
    transition: 0.5s border-color ease;
}

.checkboxInput:after {
    content: '';
    display: block;
    position: absolute;
    box-sizing: content-box;
    top: 50%;
    left: 50%;
    transform-origin: 50% 50%;
    background-color: rgb(0, 68, 107);
    width: 16px;
    height: 16px;
    border-radius: 100vh;
    transform: translate(-50%, -50%) scale(0);
}

.checkboxInput[type="checkbox"]:before {
    border-radius: 16px/4;
}

.checkboxInput[type="checkbox"]:after {
    width: 9.6px;
    height: 16px;
    border-radius: 0;
    transform: translate(-50%, -85%) scale(0) rotate(45deg);
    background-color: transparent;
    box-shadow: 4px 4px 0px 0px rgb(0, 68, 107);
}

.checkboxInput[type="checkbox"]:checked:after {
    animation: toggleOnCheckbox 0.2s ease forwards;
}

.checkboxInput[type="checkbox"].filled:before {
    border-radius: 16px/4;
    transition: 0.2s border-color ease, 0.2s background-color ease;
}

@keyframes toggleOnCheckbox {
    0% {
        opacity: 0;
        transform: translate(-50%, -85%) scale(0) rotate(45deg);
    }

    70% {
        opacity: 1;
        transform: translate(-50%, -85%) scale(0.9) rotate(45deg);
    }

    100% {
        transform: translate(-50%, -85%) scale(0.8) rotate(45deg);
    }
}

.input-range-wrapper {
    padding: 6px 10px 14px 10px;
    /* box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.028); */
    margin: 3px 0 5px 0;
    border-radius: 15px;
}

.slider-text {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.slider-text>p {
    margin: 0;
    padding: 0;
    font-weight: 500;
    margin: 0 4px 0 4px;
}

.buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.filterButton {
    padding: 5px;
    color: white;
    background-color: var(--primary-color);
    border-radius: 5px;
    font-size: 20px;
    width: 100px;
    text-align: center;
    margin: 16px 0 10px 0;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
    box-sizing: border-box;
    border: 1px solid var(--primary-color);
}

.filterButton:hover {
    color: var(--primary-color);
    background-color: #fff;
}

.resetButton {
    color: rgb(110, 110, 110);
    background: 1px solid rgb(110, 110, 110);
    padding: 5px 15px;
    border: none;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    margin: 5px 0 25px 0;
}


.tredingButton {
    margin-top: 60px;
    margin-bottom: 10px;
}

.footerText {
    font-size: 20px;
    margin-bottom: 20px;
}

.buttonText {
    padding: 3px 5px 3px 5px;
    color: rgb(62, 77, 225);
    background-color: rgb(236, 237, 252);
    border: none;
}

.allButtons {
    display: flex;
    justify-content: space-between;
    margin-right: 30px;
}

.buttontext {
    font-size: 14px;
}

.componentBox {
    margin-top: 15px;
    margin-left: 40px;
    margin-right: 15px;
}

.priceLabel {
    margin-left: 5px;
    margin-right: 35px;
}

.timeFilter {
    margin-right: -20px;
}

.timeTable {
    display: flex;
    width: 80px;
    color: black;
    justify-content: center;
    margin: 4px 6px;
    padding: 1px 1px;
    border-radius: 6px;
    /* margin-left: 10px; */
    border: 1px solid grey;
}

.selected {
    background-color: var(--primary-color);
    color: white;
}

.scrollMenu {
    display: flex;
    width: 180px;
    min-inline-size: -webkit-fill-available;
    float: left;
    font-size: 13px;
    margin: 2px auto;
    max-width: 800px;
    padding: 1px 1px;
    border-bottom: 1px solid rgb(235, 235, 235);
    overflow: auto;
    white-space: nowrap;
}

/* width */
.scrollMenu::-webkit-scrollbar {
    width: 2px !important;
    height: 3px;
}

/* Handle */
.scrollMenu::-webkit-scrollbar-thumb {
    background: var(--primary-color);
}

.h1 {
    font-size: 22px;
    font-weight: 600;
}

/* Handle on hover */
.scrollMenu::-webkit-scrollbar-thumb:hover {
    background: #555;
}

@media only screen and (max-width: 768px) {
    .homePageWrapper {
        margin-left: 5px;
    }

    .componentBox {
        margin-left: 5px;
    }

    .scrollMenu {
        width: 110px;
        margin-left: 10px;
    }

    .checkboxFilter {
        font-size: .9rem;
    }

    .timeFilter {
        margin-right: 3px;
    }

    .textFilter {
        font-size: .9rem;
    }

    .resetButton {
        font-size: .8rem;
    }

    .filterButton {
        font-size: 1rem;
    }
}

@media screen and (max-width: 520px) {
    .divider {
        display: none;
    }

    .blocksfilter {
        margin-top: 0;
    }

    .fBlockFilter {
        width: 100%;
        margin: 5px 0;
    }

    .priceBlockFilter {
        margin-top: 15px;
    }
}