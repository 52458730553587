.accordion {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.head {
    display: flex;
    justify-content: space-between;
    padding: 15px 15px;
    /* border-top-left-radius: 10px;
    border-top-right-radius: 10px; */
}
    

.button {
    background-color: #80CDE9;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
}

.body {
    padding: 15px 15px;
}

.name {
    margin: 0;
    font-size: 18px;
}

.isOpen {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: rgba(51, 71, 168, 0.15);
}