.sidenav {
  width: 98px;
  height: 100dvh !important;
  background-color: var(--primary-color);
  border-top-right-radius: 50px 40px;
  animation-duration: 0.6s;
  overflow-x: hidden;
  z-index: 11000;
  transition: width 0.3s ease;
  transform: translateX(0);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 9997;
}

.sideNavMobile {
  position: fixed;
}

.hideAnimation {
  width: 0;
  transition: width 0.3s ease;
}

.showAnimation {
  display: flex;
  transition: width 0.3s ease;
}

.img {
  margin-top: 50px;
  width: 100px;
}

.logo {
  margin-top: 50px;
  padding: 5px;
  width: 84px;
}

.buttonsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 58px;
  width: 58px;
  color: white;
  cursor: pointer;
  margin: 15px 0;
  border: 1px solid transparent;
  text-decoration: none;
  padding: 5px;
}

.activeButton {
  background-color: white;
  color: var(--primary-color);
  border: 1px solid white;
  border-radius: 50%;
  box-shadow: 4px 4px 6px 2px rgba(0, 0, 0, 0.28);
}

.buttonText {
  margin-top: 6px;
}

.showSideNavBtn {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 10px;
  color: var(--secondary-color);
  background-color: var(--primary-color);
  padding: 5px;
  border-radius: 0 50% 50% 0;
  height: 45px;
  width: 45px;
  box-shadow: 4px 4px 6px 2px rgb(0 0 0 / 28%);
  border: solid 1px white;
  border-left: 0;
  cursor: pointer;
  z-index: 1000;
}

.showSideNavBtnDetails {
  bottom: 80px;
}

.hideSideNavBtn {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 13px;
  color: var(--secondary-color);
  padding: 5px;
  height: 40px;
  width: 40px;
  box-shadow: -5px 0px 5px 0px rgb(0 0 0 / 28%);
  border-radius: 50%;
  cursor: pointer;
}

.hideSideNavBtnDetails {
  bottom: 85px;
}