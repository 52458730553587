@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

* {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

:root {
  --primary-color: #00446b;
  --success-color: rgb(42, 165, 58);
  --error-color: rgb(189, 7, 7);
  --secondary-color: white;
  --orange: rgb(253, 198, 84);
  --bp-medium: 768px;
  --bp-small: 576px;
  /* --animate-duration: 1000ms;  */
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.more-div {
  max-width: 1400px;
  margin: 10rem auto;
}
.more-div >h2 {
  text-align: center;
  color: var(--primary-color);
}
.card-wrap {
  display: flex;
  flex-wrap: wrap; 
  justify-content: space-evenly; 
  padding: 1px;
  gap: 1px;
}
.card-wrap {
  width: fit-content;
  max-width: 1800px; /* Limit container width on large screens */
  display: flex;
  flex-wrap: wrap; /* Wrap cards onto multiple lines */
  justify-content: space-between; /* Distribute cards evenly */
  margin: 0 auto; /* Center the grid horizontally */
  padding: 1rem;
  gap: 1px; /* Add spacing between cards */
}

.card {
  display: flex;
  flex-direction: column;
  flex: 0 0 45%; 
  min-width: 250px; 
  margin: 1rem 0.5rem; 
  border-radius: 0.5rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  font-family: sans-serif;
  overflow: hidden; 
}

/* Media queries for additional responsiveness */

@media (max-width: 768px) {
  .card {
    flex: 0 0 50%; /* Adjust card width for smaller screens (e.g., tablets) */
  }
}

@media (max-width: 480px) {
  .card {
    flex: 1 0 100%; /* Make cards full-width on very small screens (e.g., smartphones) */
    margin: 0.5rem; /* Adjust margins for smaller screens */
  }
}

  .card-image-wrapper {
	overflow: hidden;
  }
  
  .card-image {
	width: 100%;
	height: 150px; /* Adjust image height as needed */
	object-fit: cover; /* Maintain image aspect ratio */
	transition: transform 0.3s ease-in-out;
  }
  
  .card:hover .card-image {
	transform: scale(1.1); /* Zoom on hover */
  }
  
  .card-content {
	padding: 1rem;
	flex: 1; /* Allow content to fill remaining space */
  }
  
  .card-heading {
	font-size: 1.2rem;
	margin-bottom: 0.5rem;
	color: #333;
  }
  
  .card-subtitle {
	color: #888;
	margin-bottom: 1rem;
  }
  
  .card-list {
	list-style: none;
	padding: 0;
	margin: 0;
  }
  
  .card-list-item {
	margin-bottom: 0.5rem;
  }
  
  .card-link {
	text-decoration: none;
	color: #007bff;
	font-weight: bold;
	transition: all 0.3s ease-in-out;
  }
  
  .card-link:hover {
	color: #0056b3;
  }
  
.loaderWrapperRecents {
  height: 100%;
  min-height: max-content;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  margin-bottom: 5%;
}

.loader {
  border: 8px solid rgba(63, 143, 201, 0.1);
  border-radius: 50%;
  border-top: 10px solid var(--primary-color);
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
input,
textarea {
  background-color: rgb(242, 243, 254);
  border: 1px solid #e2e5fe;
  padding: 10px;
}

input {
  -webkit-appearance: none !important;
  appearance: none !important;
}


/* utils */
.border-top {
  border-top: 4px solid var(--primary-color);
}

.border-top-1 {
  border-top: 1px solid var(--primary-color);
}

.border-top-2 {
  border-top: 2px solid var(--primary-color);
}

.border-top-3 {
  border-top: 3px solid var(--primary-color);
}

.border-warning {
  border: 2px solid rgb(140, 10, 10) !important;
}

.text-primary {
  color: var(--primary-color);
  
}
.position{
  display: flex;
  align-items: center;
  justify-content: center;
}


.text-success {
  color: var(--success-color);
}
.text-green {
    color: var(--success-color);
    text-align: center;
    text-decoration: underline;
  }

  .text-orange {
    color: var(--orange);
  }

.text-danger{
  color: var(--error-color);
}
.text-black{
  color: black;
}

.text-light {
  color: rgba(109, 109, 109, 0.8);
}

.text-white {
  color: #fff;
}

.bg-orange {
  background-color: var(--orange);
}

.bg-success {
  background-color: var(--success-color);
}

.container {
  padding: 0.5rem 2rem 2rem 2rem;
}

.rounded {
  border-radius: 4px;
}

/* Text utils */
.decoration-none {
  text-decoration: none;
}

/* Shadows */
.shadow-1 {
  box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.15); 
}

.shadow-2 {
  box-shadow: 0px 0px 10px 6px #cccc;
}

.shadow-bottom-1 {
  box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.shadow-bottom-2 {
  box-shadow: 0px 10px 10px 6px #cccc;
}

/*  Margins */

.m-0 {
  margin: 0;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mt-3 {
  margin-top: 3rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.ml-1 {
  margin-left: 1rem;
}

.mr-1 {
  margin-right: 1rem;
}

.mt-0 {
  margin-top: 0rem;
}

.mb-0 {
  margin-bottom: 0rem;
}

.ml-0 {
  margin-left: 0rem;
}

.mr-0 {
  margin-right: 0rem;
}

.m-auto {
  margin: auto
}

/* Borders */
.border-0 {
  border: none;
}

.border-inset {
  border: inset;
  border-width: 1px;
}

/* Cursor */
.cursor-pointer {
  cursor: pointer;
}


/* Display */
.d-flex {
  display: flex;
}

.d-inline {
  display: inline;
}

.d-none {
  display: none;
}

.content-end {
  justify-content: end;
}

.text-right {
  text-align: right;
}

/* Responsive */
.row,
.row-lg,
.row-md,
.row-sm {
  display: flex;
  flex-direction: row;
}

.col {
  display: flex;
  flex-direction: column;
}

.wrap {
  flex-wrap: wrap;
}

.grid-item {
  flex-grow: 1;
  /* height: fit-content; */
}

.f-25 {
  flex: 25%;
}

.f-50 {
  flex: 50%;
}

.f-75 {
  flex: 75%;
}

.f-100 {
  flex: 100%;
}

.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.w-100 {
  width: 100%;
}

.centered {
  align-items: center;
  justify-content: center;
}

.align-baseline {
  align-items: baseline;
}

.gap-1 {
  gap: 1rem;
}

.image-gallery-slides {
  border-radius: 25px;
}

.image-gallery-fullscreen-button>.image-gallery-svg {
  height: 35px !important;
  width: 35px !important;
}

.image-gallery-svg {
  height: 70px !important;
  width: 40px !important;
}



@media screen and (max-width: 1200px) {
  .row-lg {
    flex-direction: column;
  }

  .d-none-lg {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .row-md {
    flex-direction: column;
  }

  .d-none-md {
    display: none;
  }

  .image-gallery-fullscreen-button>.image-gallery-svg {
    height: 25px !important;
    width: 25px !important;
  }
  
  .image-gallery-svg {
    height: 60px !important;
    width: 35px !important;
  }
}

@media screen and (max-width: 768px) {
  .row-sm {
    flex-direction: column;
  }

  .d-none-sm {
    display: none;
  }
}

@media screen and (max-width: 576px) {
  .row-xs {
    flex-direction: column;
  }

  .d-none-xs {
    display: none;
  }

  .container {
    padding: 10px;
  }
  .photo{
    border-image-width: auto;
    align-content: center;
  }
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* NotFound.module.css */
.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #696969;
  background-size: cover;
  background-position: center center;
  z-index: 0;
}
.not-found-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #0009;
  z-index: 1;


}
.not-found-logo {
  width: 200px; /* Adjust logo width as needed */
  margin-bottom: 2rem;
  z-index: 2;
}

.not-found-h1 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: var(--orange);
  z-index: 2;
}

.not-found-p {
  font-size: 1.2rem;
  color: #e8e8e8;
  max-width: 720px;
  margin-bottom: 2rem;
  text-align: center;
  z-index: 2;
}

.not-found-link {
  background-color: var(--primary-color);
  color: #fff;
  padding: 1rem 2rem;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.2s ease;
  z-index: 2;
}

.not-found-link:hover {
  background-color: #222;
}


::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: transparent;
  margin-left: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: var(--primary-color);
}