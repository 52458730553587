.arrowWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.arrowButton {
    display: flex;
    align-items: center;
    background-color: var(--primary-color);
    border: none;
    border-radius: 50%;
    color: white;
    cursor: pointer;
    font-size: 20px;
    height: 25px;
    width: 25px;
    padding-top: 2.5px; /* FUN */
}

.prevButton {
    margin-right: 7px;
}

.nextButton {
    margin-left: 7px;
    padding-left: 7px;
}

.disabledArrowTime {
    opacity: 0.5;
}

.timeDisplay {
    font-weight: 500;
}

.timeDisplay > p {
    margin: 0;
}