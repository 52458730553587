.recentListings {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.loader {
  border: 8px solid rgba(63, 143, 201, 0.1);
  border-radius: 50%;
  border-top: 10px solid var(--primary-color);
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.searchListingLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.popupNoTripsText > a {
  color: var(--primary-color);
  font-weight: 600;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}