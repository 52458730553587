.itemWrapper {
    display: flex;
    align-items: center;
    margin: 10px 0;
    padding: 0 10px;
    background: radial-gradient(circle 10px at right, #0000 97%, white) right,
        radial-gradient(circle 10px at left, #0000 97%, white) left;
    background-size: 51% 100%;
    background-repeat: no-repeat;
    border-radius: 4px;
    filter: drop-shadow(0 0 2px rgb(0 0 0 / 40%));
    width: fit-content;
}

.itemInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.imageWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8px 10px;
}

.imageWrapper img {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    object-fit: cover;
}

.pricePerPerson {
    font-size: 15px;
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 16px;
    align-self: flex-end;
}

.itemButton {
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color);
    cursor: pointer;
    color: white;
    border-radius: 5px;
    font-size: 15px;
    padding: 10px 15px;
    transition: all 0.3s ease;
}

.itemButton:hover {
    background-color: white;
    color: var(--primary-color);
}

.ticketInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 9999;
}

.title {
    font-size: 20px;
    padding: 2px 0;
    color: var(--primary-color);
    font-weight: 500;
    overflow: auto;
    white-space: nowrap;
    text-wrap: wrap;
    max-width: 65%;
}

/* width */
.title::-webkit-scrollbar {
    width: 2px !important;
    height: 2px;
}

/* Handle */
.title::-webkit-scrollbar-thumb {
    background: var(--primary-color);
}

/* Handle on hover */
.title::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.buttonWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
}

.buttonTourInfo {
    border-radius: 6px;
    background: var(--primary-color);
    padding: 4px 13px;
    color: white;
    font-size: 12px;
    border: none;
    margin-top: 9px;
}

.textamenties {
    font-size: 14px;
    color: rgb(175, 175, 175);
}

.style.starRating {
    font-size: 10px;
}

.iconList,
.iconListChild,
.iconListCalendar {
    display: flex;
    align-items: center;
    margin-top: 3px;
}

.iconListCalendar {
    margin-bottom: 10px;
}

.iconListChild {
    margin-left: 15px;
}

.iconListChild>i,
.iconList>i,
.iconListCalendar>i {
    margin-right: 5px;
}

.iconListItem {
    border-radius: 8px;
    color: var(--primary-color);
}

.startDate {
    display: inline-flex;
    color: grey;
    margin-left: 5px;
}

.peopleNumber {
    color: grey;
    margin: 2px 0 0 5px;
}

.startTripDate {
    color: grey;
    margin-top: 2px;
}

.moreInfoWrapper {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 300px;
}


.infoWrapper {
    background-color: rgb(247, 248, 254);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: stretch;
    padding: 8px 10px;
    max-width: 300px;
}

.infoTag {
    border-radius: 6px;
    border: 1px solid var(--primary-color);
    padding: 2px 12px;
    color: var(--primary-color);
    font-size: 12px;
    text-align: center;
    margin-left: 5px;
}

.iconWrapper,
.depAndTime {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    justify-content: space-between;
}

.depAndTime {
    align-items: center;
    justify-content: center;
    width: 50%;
}

.peopleList {
    display: flex;
    align-items: center;
}

.depAndTime>p {
    padding: 0;
}

.depCity {
    font-size: 18px;
    color: rgb(168, 174, 240);
    font-weight: 500;
    margin: 0 0 10px 0;
}

.timeOfDep {
    color: grey;
    margin: 0;
}

.scrollMenu {
    display: flex;
    width: 190px;
    min-inline-size: -webkit-fill-available;
    float: left;
    font-size: 13px;
    margin: 2px auto;
    margin-right: 10px;
    max-width: 800px;
    padding: 1px 1px;
    border-bottom: 1px solid rgb(235, 235, 235);
    overflow: auto;
    white-space: nowrap;
}

/* width */
.scrollMenu::-webkit-scrollbar {
    width: 2px !important;
    height: 3px;
}

/* Handle */
.scrollMenu::-webkit-scrollbar-thumb {
    background: var(--primary-color);
}

/* Handle on hover */
.scrollMenu::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.mainWrapepr {
    display: flex;
}

.timeTable {
    display: flex;
    width: 80px;
    color: black;
    justify-content: center;
    margin: 4px 6px;
    padding: 1px 1px;
    border-radius: 6px;
    margin-right: 10px;
    border: 1px solid grey;
}

.selected {
    background-color: var(--primary-color);
    color: white;
}

.infoTicketButton {
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color);
    cursor: pointer;
    color: white;
    border-radius: 5px;
    font-size: 13px;
    padding: 2px 10px;
}

@media only screen and (max-width: 576px) {
    .moreInfoWrapper {
        width: 100%;
    }

    .itemWrapper {
        width: 100%;
        margin: 0;
        padding: 0;
    }
}