.range-input {
    position: relative;
    border: none;
}

.min-range,
.max-range {
    position: absolute;
    width: 100%;
    height: 5px;
    top: -12px;
    z-index: 5;
    pointer-events: none;
    background: none;
    appearance: none;
    accent-color: var(--primary-color);
    border: none;
    margin-left: 0;
}

.min-range::-webkit-slider-thumb,
.max-range::-webkit-slider-thumb {
    height: 16px;
    width: 16px;
    background-color: var(--primary-color);
    border-radius: 50%;
    pointer-events: auto;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
    margin-left: -10px;
}

.slider-container {
    height: 6px;
    position: relative;
    background-color: #ddd;
    border-radius: 5px;
    margin-top: 10px;
}

.progress {
    position: absolute;
    height: 100%;
    background-color: var(--primary-color);
    border-radius: 5px;
}