.bannerWrapper {
  background-color: rgb(242, 243, 254);
  border-radius: 20px;
  padding: 15px 10px;
  overflow: auto;
  max-height: 80vh;
}

.topText {
  display: flex;
  justify-content: space-between;
}

.text {
  font-size: 18px;
  font-weight: 600;
}

.buttons {
  display: flex;
}

.noResult {
  margin-top: 10px;
}

.noResultText {
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 5px 0;
  color: var(--primary-color);
}

.noResultSubText {
  font-size: 16px;
  font-weight: 600;
  color: rgb(110, 110, 110);
  margin-bottom: 3px;
}


@media screen and (max-width: 768px) {
	.bannerWrapper {
		margin: 0 15px 20px 15px !important;
		max-height: 80vh;
		padding: 20px 5px;
    max-height: unset;
	}

  .bannerWrapper > div {
    justify-content: center;
  }
}
